/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */

export default function Settings({ children }) {
  return children({
    mainNavigation: [
      {
        label: 'Peinture',
        href: '/fr/art/peintures',
        columns: [
          {
            items: [
              {
                label: 'Peintures prestigieuses',
                href: '/fr/art/peintures?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/peintures?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/peintures?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les peintures',
                href: '/fr/art/peintures',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Toutes les peintures',
                href: '/fr/art/peintures',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Peintures abstraites', href: '/fr/art/peintures/abstrait', className: '' },
              {
                label: 'Peintures figuratives',
                href: '/fr/art/peintures/figuratif',
                className: '',
              },
              { label: "Peintures à l'huile", href: '/fr/art/peintures/huile', className: '' },
              { label: 'Peintures acryliques', href: '/fr/art/peintures/acrylique', className: '' },
              { label: 'Peintures de paysages', href: '/fr/art/peintures/paysage', className: '' },
              { label: 'Peintures Art déco', href: '/fr/art/peintures/art-deco', className: '' },
              { label: 'Peintures de nus', href: '/fr/art/peintures/nu', className: '' },
              { label: 'Peintures Pop Art', href: '/fr/art/peintures/pop-art', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles peintures',
                text: 'Des créations originales d’artistes en pleine ascension',
                href: '/fr/collection/1693/nos-plus-belles-peintures ',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/f1136826-5789-46f4-a405-b62da2b03100.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Édition',
        href: '/fr/art/edition',
        columns: [
          {
            items: [
              {
                label: 'Éditions prestigieuses',
                href: '/fr/art/edition?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/edition?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/edition?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les éditions',
                href: '/fr/art/edition',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Toutes les éditions',
                href: '/fr/art/edition',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Editions Street Art', href: '/fr/art/edition/street-art', className: '' },
              { label: 'Editions Pop Art', href: '/fr/art/edition/pop-art', className: '' },
              { label: 'Editions paysages', href: '/fr/art/edition/paysage', className: '' },
              { label: 'Editions botaniques', href: '/fr/art/edition/botanique', className: '' },
              { label: 'Editions animaux', href: '/fr/art/edition/animaux', className: '' },
              { label: 'Sérigraphies', href: '/fr/art/edition/serigraphie', className: '' },
              { label: 'Impressions sur lino', href: '/fr/art/edition/lino', className: '' },
              { label: 'Impressions monochromes', href: '/fr/art/edition/mono', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles éditions',
                text: 'Des tirages de qualité supérieure pour les collectionneurs exigeants',
                href: '/fr/collection/1697/nos-plus-belles-editions',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4d0aadcd-63e2-4bb7-a1f0-ec1bd9a54960.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Photographie',
        href: '/fr/art/photographie',
        columns: [
          {
            items: [
              {
                label: 'Photographies prestigieuses',
                href: '/fr/art/photographie?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/photographie?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/photographie?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Tous les photographes',
                href: '/fr/art/photographie',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Tous les photographes',
                href: '/fr/art/photographie',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                label: 'Photographie abstraite',
                href: '/fr/art/photographie/abstrait',
                className: '',
              },
              {
                label: 'Photographie de paysage',
                href: '/fr/art/photographie/paysage',
                className: '',
              },
              { label: 'Photographie de nu', href: '/fr/art/photographie/nu', className: '' },
              {
                label: 'Photographie noir et blanc',
                href: '/fr/art/photographie/noir-et-blanc',
                className: '',
              },
              {
                label: 'Photographie numérique',
                href: '/fr/art/photographie/numerique',
                className: '',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles photographies',
                text: 'Vivez à travers l’objectif de nos photographes',
                href: '/fr/collection/1698/nos-plus-belles-photographies',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4e016d65-8de4-4c70-9530-a91a81bb16f9.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Sculpture',
        href: '/fr/art/sculpture',
        columns: [
          {
            items: [
              {
                label: 'Sculptures prestigieuses',
                href: '/fr/art/sculpture?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/sculpture?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/sculpture?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Toutes les sculptures',
                href: '/fr/art/sculpture',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Toutes les sculptures',
                href: '/fr/art/sculpture',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Sculpture abstraite', href: '/fr/art/sculpture/abstrait', className: '' },
              { label: 'Sculpture figurative', href: '/fr/art/sculpture/figuratif', className: '' },
              { label: 'Sculpture de nu', href: '/fr/art/sculpture/nu', className: '' },
              { label: 'Sculpture en métal', href: '/fr/art/sculpture/metal', className: '' },
              { label: 'Sculpture en bronze', href: '/fr/art/sculpture/bronze', className: '' },
              { label: 'Sculpture en bois', href: '/fr/art/sculpture/bois', className: '' },
              {
                label: 'Sculpture en céramique',
                href: '/fr/art/sculpture/ceramique',
                className: '',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus belles sculptures',
                text: 'De l’infiniment petit à la sculpture monumentale',
                href: '/fr/collection/1694/nos-plus-belles-sculptures',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/0f3bacf7-e68e-4289-80bf-0757f0b1160f.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Dessin',
        href: '/fr/art/dessins',
        columns: [
          {
            items: [
              {
                label: 'Dessins prestigieux',
                href: '/fr/art/dessins?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Art émergent',
                href: '/fr/art/dessins?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveautés',
                href: '/fr/art/dessins?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              {
                label: 'Tous les dessins',
                href: '/fr/art/dessins',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { type: 'separator', className: '' },
              {
                label: 'Tous les dessins',
                href: '/fr/art/dessins',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              { label: 'Dessins réalistes', href: '/fr/art/dessins/realisme', className: '' },
              { label: 'Dessins abstraits', href: '/fr/art/dessins/abstrait', className: '' },
              {
                label: 'Dessins illustratifs',
                href: '/fr/art/dessins/illustration',
                className: '',
              },
              { label: 'Dessins de nus', href: '/fr/art/dessins/nu', className: '' },
              { label: 'Dessins de portraits', href: '/fr/art/dessins/portrait', className: '' },
              { label: 'Dessins au crayon', href: '/fr/art/dessins/crayon', className: '' },
              { label: 'Dessins au fusain', href: '/fr/art/dessins/fusain', className: '' },
              { label: "Dessins à l'encre", href: '/fr/art/dessins/encre', className: '' },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Nos plus beaux dessins',
                text: 'Les plus beaux tracés',
                href: '/fr/collection/1695/nos-plus-beaux-dessins',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/ea14d062-5e79-4d6e-b3ad-5adf548d91ad.jpg',
                className: '',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Artistes',
        href: '/fr/artistes',
        columns: [
          {
            items: [
              {
                label: 'Tous les artistes',
                href: '/fr/artistes',
                bold: true,
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: 'Peintres', href: '/fr/artistes/peintres', bold: true, className: '' },
              { label: 'Graveurs', href: '/fr/artistes/graveurs', bold: true, className: '' },
              {
                label: 'Photographes',
                href: '/fr/artistes/photographes',
                bold: true,
                className: '',
              },
              { label: 'Sculpteurs', href: '/fr/artistes/sculpteurs', bold: true, className: '' },
              {
                label: 'Dessinateurs',
                href: '/fr/artistes/dessinateurs',
                bold: true,
                className: '',
              },
              { label: 'Collagistes', href: '/fr/artistes/collagistes', bold: true, className: '' },
              { type: 'separator', className: '' },
              {
                label: 'Tous les artistes',
                href: '/fr/artistes',
                obfuscate: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Processus de curation',
                href: 'https://get.riseart.com/fr/curation/',
                obfuscate: true,
                external: true,
                bold: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Etape de carrière',
            items: [
              {
                label: 'Artistes confirmés',
                href: '/fr/artistes?scene=confirme',
                obfuscate: true,
                icon: 'established',
                bold: true,
                className: '',
              },
              {
                label: 'Artistes émergents',
                href: '/fr/artistes?scene=emergent',
                obfuscate: true,
                icon: 'emerging',
                bold: true,
                className: '',
              },
              {
                label: 'Nouveaux et remarquables',
                href: '/fr/artistes?scene=nouveau',
                obfuscate: true,
                icon: 'new',
                bold: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artistes à suivre',
                href: '/fr/collection/1415/artiste_a_suivre',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Légendes de l'art",
                href: '/fr/collection/1672/oeuvres-artistes-celebres',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Artistes à la une',
            href: '/fr/artistes',
            obfuscate: true,
            items: [
              {
                label: 'Richard Orlinski',
                href: '/fr/artiste/131214/richard-orlinski',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/8a77994f-298c-418c-8ffe-7276bbb1698c.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'René Magritte',
                href: '/fr/artiste/131229/rene-magritte',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/c2f59705-b5b9-4520-8081-44f7d3078641.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Salvador Dalí',
                href: '/fr/artiste/131204/salvador-dali',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/abe3b2f4-ee6d-4d48-8d0d-be085e16f146.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Henri Matisse',
                href: '/fr/artiste/131201/henri-matisse',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/711f0906-9359-4047-8d12-e1b28818ef79.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Pablo Picasso',
                href: '/fr/artiste/16349/pablo-picasso',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/ea0c345e-8a78-4b5f-bbfd-ea490a68b2b8.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Andy Warhol',
                href: '/fr/artiste/16350/andy-warhol',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/22d5e9f4-3712-458a-8ba9-9a702472c831.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: '',
            items: [
              {
                label: 'Anne Huet-Baron',
                href: '/fr/artiste/130762/anne-huet-baron',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/22061b99-134f-4c62-bcfd-40cca4549930.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Nelson Makamo',
                href: '/fr/artiste/63379/nelson-makamo',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2023/12/603c9976-656b-49c6-a6a4-bfcbdf7f89dc.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Nathan Chantob',
                href: '/fr/artiste/130908/nathan-chantob',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/5ed2f229-8d13-4228-9d10-0e0cebd3a68b.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Maria Esmar',
                href: '/fr/artiste/130942/maria-esmar',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/4341970c-4a6d-40d6-9f15-868be93f7ad4.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Sophie Cantou',
                href: '/fr/artiste/131003/sophie-cantou',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/1ee1a04c-be2f-43a0-aa19-4afe2695abb5.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Bernard Rives',
                href: '/fr/artiste/130868/bernard-rives',
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/05/afec4ba2-4842-4f68-b538-e30bbb302fbd.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Inspiration',
        href: '/fr/articles',
        columns: [
          {
            label: 'Edito',
            href: '/fr/articles',
            obfuscate: true,
            items: [
              {
                label: 'Edito',
                href: '/fr/articles',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              { label: "Interviews d'artistes", href: '/fr/articles/artistes', className: '' },
              {
                label: 'Actualités artistiques',
                href: '/fr/articles/actualites-artistiques',
                className: '',
              },
              { label: 'Événements à venir', href: '/fr/evenements', className: '' },
              { type: 'separator', className: '' },
              {
                label: "Catalogue d'art",
                href: 'https://get.riseart.com/fr/catalogues/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: 'Guides artistiques', href: '/fr/guides', className: '' },
              { label: "Fil d'actualités", href: '/fr/activite', className: '' },
            ],
            className: '',
          },
          {
            label: 'Collections',
            href: '/fr/collections',
            items: [
              {
                label: "Collections d'art",
                href: '/fr/collections',
                className: 'ra-hide-for-large-only ra-hide-for-xlarge',
              },
              {
                label: 'Sélections de nos Curateurs',
                href: '/fr/collection/1313/selections-de-nos-curateurs',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Grands maîtres de l'art",
                href: '/fr/collection/1672/oeuvres-artistes-celebres',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Nouveautés de la semaine',
                href: '/fr/collection/1402/nouveautes-de-la-semaine',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Wishlist du Collectionneur',
                href: '/fr/collection/222/sur-quoi-les-collectionneurs-ont-ils-loeil',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Artiste en pleine ascension',
                href: '/fr/art?scene=emergent',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: "Œuvres d'art grand format",
                href: '/fr/art?dimensions=L-XXL',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Œuvres à prix doux',
                href: '/fr/art?dimensions=S-XXL&prix=0-1500',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Services',
            items: [
              {
                label: 'Test de personnalité artistique',
                href: '/fr/quiz/debuter',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Conseils artistiques',
                href: '/fr/service-art-advisory',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              {
                label: 'Faire une offre',
                href: '/fr/article/2731/faire-une-offre',
                obfuscate: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
              { type: 'separator', className: '' },
              {
                label: 'Carte Cadeau',
                href: '/fr/cartes-cadeaux',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: "Catalogue d'art",
                text: "Œuvres d'art originales d'artistes en pleine ascension",
                href: 'https://get.riseart.com/fr/catalogues/',
                external: true,
                obfuscate: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/01/7ccabfe5-ea41-4ae0-b3d7-de56cc808e2d.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
      {
        label: 'Art Advisory',
        href: '/fr/service-art-advisory',
        obfuscate: true,
        columns: [
          {
            label: "Service d'Art Advisory",
            href: '/fr/service-art-advisory',
            obfuscate: true,
            items: [
              {
                label: 'Curation',
                href: 'https://get.riseart.com/fr/curation/',
                obfuscate: true,
                external: true,
                className: '',
              },
              { label: "Service d'Art Advisory", href: '/fr/service-art-advisory', className: '' },
              {
                label: "Location d'œuvres d'art",
                href: '/fr/a-propos/location-art',
                className: '',
              },
              {
                label: 'Commandes sur mesure',
                href: 'https://get.riseart.com/fr/commandes/',
                obfuscate: true,
                external: true,
                className: '',
              },
              {
                label: 'Retours gratuits',
                href: 'https://get.riseart.com/fr/retours',
                obfuscate: true,
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contacter un conseiller artistique',
                href: '/fr/service-art-advisory#formulaire-renseignements',
                obfuscate: true,
                external: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            label: 'Service pour les Pros',
            items: [
              {
                label: "Leasing d'art",
                href: 'https://pro.riseart.com/fr/services/leasing-art',
                target: '_blank',
                external: true,
                className: '',
              },
              {
                label: 'Art et Bureaux',
                href: 'https://pro.riseart.com/fr/clients/art-au-bureau',
                target: '_blank',
                external: true,
                className: '',
              },
              {
                label: 'Art et Décorateurs d’intérieur',
                href: 'https://pro.riseart.com/fr/clients/art-decoration-interieure',
                target: '_blank',
                external: true,
                className: '',
              },
              {
                label: 'Art et Hôtellerie',
                href: 'https://pro.riseart.com/fr/clients/art-hotels',
                target: '_blank',
                external: true,
                className: '',
              },
              { type: 'separator', className: '' },
              {
                label: 'Contacter notre équipe',
                href: 'https://riseart.typeform.com/riseartpro?typeform-source=pro.riseart.com',
                obfuscate: true,
                external: true,
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
          {
            items: [
              {
                type: 'box',
                label: 'Soho Home x Rise Art',
                text: "Recréez l'ambiance des studios Soho Home grâce à nos collections dédiées",
                href: 'https://get.riseart.com/fr/sohohome/',
                obfuscate: true,
                external: true,
                image:
                  'https://d1ee3oaj5b5ueh.cloudfront.net/original/file/2024/10/4b8a26a1-78a1-44f9-8c86-0aed75a552bb.jpg',
                className: 'ra-show-for-large ra-hide-for-small-only ra-hide-for-medium-only',
              },
            ],
            className: '',
          },
        ],
        className: '',
      },
    ],
    footerLinks: [
      {
        name: 'POUR LES ARTISTES',
        links: [
          {
            label: 'Soumettez Votre Art',
            href: 'https://get.riseart.com/fr/soumettre/art/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Espace Vendeur',
            href: 'https://sellers.riseart.com?locale=fr-FR',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'POUR LES ACHETEURS',
        links: [
          {
            label: 'Avis Clients',
            href: 'https://www.reviews.io/company-reviews/store/riseart-com',
            obfuscate: true,
            external: true,
          },
          {
            label: 'La Curation Rise Art',
            href: 'https://get.riseart.com/fr/curation/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Retours',
            href: 'https://get.riseart.com/fr/retours/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'Encadrez Votre Œuvre',
            href: 'https://get.riseart.com/fr/encadrement/',
            obfuscate: true,
            external: true,
          },
          {
            label: 'FAQ Clients',
            href: 'https://aide.riseart.com/',
            obfuscate: true,
            external: true,
          },
        ],
      },
      {
        name: 'À PROPOS',
        links: [
          { label: 'À propos', href: '/fr/a-propos', obfuscate: true, external: false },
          { label: 'Contactez-nous', href: '/fr/contactez-nous', obfuscate: true, external: false },
          { label: 'Cartes Cadeaux', href: '/fr/cartes-cadeaux', obfuscate: true, external: false },
          { label: 'Mentions Légales', href: '/fr/legal', obfuscate: true, external: false },
        ],
      },
    ],
  });
}
